.searchContainer {
    display: block;
    transition: all 300ms;
}

.searchContainerScrolling {
    opacity: 0;
    transition: all 300ms;
}

.topLinks {
    display: flex;
    width: 100%;
    justify-content: center;
    transform: translate(0, -10px);
    .topLink {
        color: white;
        margin-right: 10px;
        margin-left: 10px;
        text-decoration: none;
        img {
            margin-left: 5px;
            margin-right: 5px;
            width: 25px;
            height: 25px;
        }
        &.active:first-of-type{
            border-bottom: 2px solid;
            border-image: linear-gradient(to right, #da251d 60%, transparent 50%) 100% 1;
        }
        &.active:last-of-type {
            border-bottom: 2px solid;
            border-image: linear-gradient(to left, #da251d 40%, transparent 50%) 100% 1;
        }
    }
}

.searchWrapper {
    padding: 22px 0 15px;
    background-color: #254594;
    position: sticky;
    width: 100%;
    top: 69px;
    z-index: 3;
    @media (min-width: 768px) {
        top: 87px;
    }
    .container{
        
        .inputGroup {
            margin-bottom: 23px;
            height: 62px;
            .formControl {
                border-radius: 0!important;
                padding: 0.375rem 3.5rem;
                &:focus {
                    box-shadow: none;
                }
            }
            .buttonAddon {
                border: none;
                background-color: #ffffff;
                border-radius: 0;
                &:focus {
                    box-shadow: none;
                }
                svg {
                    fill: rgba(25, 28, 56, 0.6);
                }
            }
        }

        .search-action {
            span {
                color: #ffffff;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
            }
            .download-all {
                color: #ffffff;
                display: inline-block;
                text-decoration: none;
                padding: 5px 9px;
                background-color: rgba(25, 28, 56, 0.6);
                font-family: 'Barlow';
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
                @media (min-width: 576px) {
                    margin-left: auto;
                    margin-bottom: 0;
                }
            }
            .select-wrapper {
                display: flex;
                align-items: center;
                @media (min-width: 576px) {
                    padding-top: 0;
                    margin-left: 20px;
                }
                .form-select {
                    height: 32px;
                    max-width: 80px;
                    width: 100%;
                    border: none;
                    background-color: #dce4f7;
                    font-family: 'Barlow';
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #191c38;
                    border-radius: 0;
                    margin-right: 5px;
                    -moz-appearance: none;
                    &:last-of-type {
                        margin-right: 0;
                    }
                    @media (min-width: 576px) {
                        max-width: 96px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    
    
}


.searchWrapperScrolling {
    padding: 22px 0 15px;
    @media (min-width: 768px) {
        top: 87px;
    }
    opacity: 0;
    position: sticky;
    transition: all 300ms;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    transition: all 300ms;
    .categoryLink {
        margin: 0 10px;
        padding: 0 10px;
        color: white;
        align-self: center;
        cursor: pointer;
    }
    .categoryLink:hover {
        color: #dce4f7;
    }
}

.containerScrolling {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    transition: all 300ms;
    .categoryLink {
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        padding: 0 10px;
        color: white;
        text-align: center;
        cursor: pointer;
        p {
            margin: 0;
        }
        small {
            font-size: x-small;
        }
    }
    .categoryLink:hover {
        color: #dce4f7;
    }
}

.item {
    margin-right: 20px;
    width: 193px;
    max-height: 38px!important;
    border-radius: 0!important;
}

.item > div{
    border: 1px solid white;
    border-radius: 0!important;
    box-shadow: none!important;
    background-color: #DCE4F7;
    font-family: 'Barlow';
    color: black!important;
}

.refreshButton{
    border: solid white 1px!important;
    background-color: #254594!important;
    color: white!important;
    border-radius: 3px;
    transition:all 300ms;
    text-align: center;
    font-family: 'Barlow';
    padding-bottom: 6.5px!important;
    font-size: 1.7rem!important;
    line-height: 18px!important;
    height: 38px!important;
    transition: all 300ms;
    border-radius: 0!important;
    svg {
        // margin-bottom: 6.5px;
        margin-right: 10px;
        margin-left: 5px
    }
    &:hover{
        opacity: 0.8;
    }
}

.buttonM {
    svg {
        margin-bottom: 2px;
    }
    .mglass{
        padding-bottom: 5px;
        margin-right: 3px;
    }
}

.refreshButton:hover{
    opacity: 0.9;
    background-color: #9d9d9d;
}

.datepickerButton{
    border: solid white 1px!important;
    background-color: #DCE4F7!important;
    color: white!important;
    margin-right: 10px;
    height: 38px!important;
    border-radius: 0!important;
}
.datepickerButton:hover{
    opacity: 0.9;
    background-color: #9d9d9d;
}
.changeLayoutButton{
    border: solid white 1px!important;
    background-color: #DCE4F7!important;
    color: white!important;
    margin-right: 10px;
    width: 38px!important;
    height: 38px!important;
    border-radius: 0!important;
}
.changeLayoutButton:hover{
    opacity: 0.9;
    background-color: #9d9d9d;
}
.calendarWrapper{
    position: absolute; 
    display: flex;
    right: 300px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.calendar{
    visibility: hidden;
}

.resetFilterButton{
    border: solid white 0px!important;
    // background-color: #1c2e6d!important;
    color: white!important;
    border-radius: 3px;
    transition:all 300ms;
    margin: auto 5px;
    cursor: pointer;
    .x {
        margin-right: 5px;
        padding-bottom: 3px;
    }
}
.resetFilterButton:hover{
    opacity: 0.9;
    background-color: #9d9d9d;
}

.infoBar {
    display: flex;
    align-items: center;
    span {
        padding-top: 0px;
        color: white;
        font-family: 'Barlow';
    }
}

.selectButton{
    position: relative;
    font-family: 'Barlow';
    img{
        margin-bottom: 5px;
        margin-right: 5px;
    }
    &:hover{
        background-color: transparent!important;
    }
}

// .selectButton .tooltip {
//     visibility: hidden;
//     width: 120px;
//     background-color: #191c38;
//     color: #fff;
//     text-align: center;
//     padding: 5px 0;
//     border-radius: 6px;
//     position: absolute;
//     white-space: nowrap;
//     width: 200px;
//     bottom: 120%;
//     left: 50%;
//     margin-left: -100px;
//     z-index: 1;
// }

// .selectButton:hover .tooltip{
//     visibility: visible;
// }

.downloadAll {
    background-color: transparent;
    border: 0;
    margin-bottom: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow';
    img {
        margin-right: 5px;
    }
}

.buttonContainer {
    padding: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .searchButton {
        background-color: #2A50A6!important;
        color: white;
        font-size: 18px;
        font-family: 'BarlowBold';
        height: 100%;
        span {
            margin: 5px;
            @media (max-width: 768px){
                display: none;
                margin: 0px;
            }
        }
        svg {
            fill: white!important;
            margin-right: 5px;
            margin-left: 10px;
            margin-bottom: 5px;
        }
    }
}

.dates {
    color: white;
    font-family: 'Barlow';
    font-size: 14px;
    max-height: 38px;
}

.canvasButton {
    position: fixed;
    display: flex;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    background-color: #191c38;
    border-radius: 50%;
    border: solid 2px #4b54a7;
    padding: 15px;
    bottom: 5px;
    right: 5px;
    z-index: 4;
}