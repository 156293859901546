.grid-layout-video {
    padding-bottom: 75px;
    position: relative;
    .grid-layout-wrapper {
        @media (min-width: 992px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .grid-item-wrapper {
            cursor: pointer;
            @media (min-width: 992px) {
                flex: 0 0 calc(100%);
                width: calc(100%);
            }
            a {
                color: $white;
                text-align: center;
                display: block;
                margin: 0 auto;
                text-decoration: none;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 20px; 
                line-height: 25px;
                padding: 10px 0;
            }
            .layout-item {
                margin-bottom: 15px;
                position: relative;
                @media (min-width: 768px) {
                    border-right: 4px solid transparent;
                }
                &.active{
                    border-right: 4px solid $danger;
                    &:after {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        background-color: $danger;
                        position: absolute;
                        right: -8px;
                        transform: translateY(-50%) rotate(45deg);
                        top: 50%;
                        border-top: 1px solid $danger;
                        border-left: 1px solid $danger;
                    }
                }
                @media (min-width: 768px) {
                    display: flex;
                    width: 100%;
                    max-height: 158px;
                }

                &.active{
                    @media (min-width: 768px) {
                        max-height: 258px;
                    }
                }
                .layout-item-data {
                    background-color: $blue;
                    position: relative;
                    @media (min-width: 768px) {
                        flex: 0 0 213px;
                        width: 213px;
                    }
                    @media (min-width: 992px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 0 0 150px;
                        width: 150px;
                    }
                    @media (min-width: 1200px) {
                        flex: 0 0 120px;
                        width: 120px;
                    }
                    @media (min-width: 1500px) {
                        flex: 0 0 213px;
                        width: 213px;
                    }
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20px;
                        height: 3px;
                        background-color: $danger;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 18px;
                        display: none;
                    }
                    span {
                        color: $white;
                        display: block;
                        font-family: 'Barlow';
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        text-transform: uppercase;
                    }
                    p {
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 0;
                        text-transform: uppercase;
                        padding-top: 25px;
                    }
                }
                .layout-item-info {
                    background-color: $white;
                    padding: 15px 10px 16px 18px;
                    z-index: 2;
                    position: relative;
                    @media (min-width: 768px) {
                        flex: 1;
                    }
                    @media (min-width: 992px) {
                        padding: 15px 10px 16px 18px;
                    }
                    @media (min-width: 1200px) {
                        padding: 15px 10px 16px 18px;
                    }
                    h4 {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.93px;
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 9px;
                        @media (min-width: 992px) {
                            font-size: 10px;
                            line-height: 15px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 13px;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                    }
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;
                        @media (min-width: 992px) {
                            font-size: 15px;
                            line-height: 20px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                    span {
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                }
                .layout-item-action {
                    background-color: $white;
                    padding: 0px 0 16px 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    z-index: 2;
                    @media (min-width: 768px) {
                        flex: 0 0 152px;
                        width: 152px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        padding: 22px 15px 16px 10px;
                    }
                    @media (min-width: 992px) {
                        flex: 0 0 120px;
                        width: 100px;
                    }
                    .icon {
                        padding: 0 5px;
                    }
                    .photo-icon {
                        color: $secondary;
                    }
                    .action-count {
                        margin: 5px;
                    }
                }
            }
        }
        .grid-item-wrapper.active{
            display: none;
            @media (min-width: 992px){
                display: block;
                flex: 0 0 calc(45% - 12px);
                width: calc(45% - 12px);
            }
            .layout-item {
                max-height: 157%;
                .layout-item-info {
                    background-color: $white;
                    padding: 22px 10px 16px 18px;
                    z-index: 2;
                    position: relative;
                    @media (min-width: 768px) {
                        flex: 1;
                    }
                    @media (min-width: 992px) {
                        padding: 31px 36px 28px 24px;
                    }
                    @media (min-width: 1200px) {
                        padding: 31px 0 28px 24px;
                    }
                    h4 {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.93px;
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 9px;
                        @media (min-width: 992px) {
                            font-size: 10px;
                            line-height: 15px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 13px;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                    }
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;
                        @media (min-width: 992px) {
                            font-size: 15px;
                            line-height: 20px;
                            max-height: 70px;
                            max-width: 300px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        @media (min-width: 1200px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                    span {
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .grid-sidebar-wrapper {
            background-color: $white;
            @media (min-width: 992px) {
                flex: 0 0 calc(100% / 2 - 24px / 2);
                width: calc(100% / 2 - 24px / 2);
                position: sticky;
                top: 235px; 
                height: 98vh;
            }
            .grid-sidebar-main-wrapper {
                padding: 50px 18px 16px 18px;
                position: relative;
                @media (min-width: 1200px) {
                    padding: 79px 50px 31px 47px;
                }
                .close{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                    svg{
                        fill: #9D9D9D; 
                    }
                }
                .grid-sidebar-header {
                    @media (min-width: 576px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    span {
                        display: block;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 17px;
                        color: $info;
                        margin-bottom: 20px;
                        @media (min-width: 576px) {
                            margin-bottom: 0;
                        }
                        &:first-of-type {
                            margin-right: 15px;
                        }
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        &:first-of-type {
                            border: 1px solid rgba(25, 28, 56, 0.25);
                            display: flex;
                            align-items: center;
                            padding: 16px;
                            margin-right: 17px;
                        }
                        &:last-of-type {
                            background-color: $primary;
                            color: $white;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 28px;
                            padding: 12px 15px;
                            display: flex;
                            align-items: center;
                            svg {
                                fill: $white;
                                margin-right: 11px;
                            }
                        }
                    }
                }
                .grid-sidebar-content {
                    height: calc(100vh - 420px);
                    padding-right: 15px;
                    overflow-y: scroll;
                    @media(max-width: 768px){
                        max-height: 450px;
                        overflow-y: scroll;
                    }
                    .grid-sidebar-content-top {
                        padding: 38px 0 10px;
                        h4 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.93px;
                            color: #9d9d9d;
                            margin-bottom: 6px;
                            text-transform: uppercase;
                            @media (min-width: 450px) {
                                white-space: nowrap;
                            }
                        }
                        h2 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 26px;
                            color: $primary;
                            @media (min-width: 576px) {
                                font-size: 28px;
                                line-height: 34px;
                            }
                        }
                    }
                    .grid-sidebar-content-middle {
                        padding: 12px 0 15px;
                        border-top: 1px solid #eeeeee;
                        border-bottom: 1px solid #eeeeee;
                        @media (min-width: 450px) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                        }
                        span {
                            display: block;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 26px;
                            letter-spacing: -0.22px;
                            color: #9d9d9d;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            color: $primary;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 14px;
                            text-decoration: none;
                            padding-top: 20px;
                            @media (min-width: 450px) {
                                padding-top: 0;
                            }
                            svg {
                                fill: $primary;
                                margin-right: 5px;
                            }
                        }

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            white-space: pre-wrap;
                        }
                    }
                    .grid-sidebar-content-bottom {
                        padding-top: 17px;
                        p {
                            font-family: 'Barlow';
                            font-weight: normal;
                            color: $primary;
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
    .loader{
        width: 100%;
        display: flex;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
    }

    &.photo-gallery{
        @media (max-width: 768px) {
            transform: translate(0, 60px);
        }
    }
}


.video-img{
    max-height: unset!important;
    max-width: unset!important;
}

.video-figure{
    margin: unset!important;
}