.layout {
    padding-bottom: 75px;
    position: relative;
    .container {
        @media (min-width: 768px) {
            max-width: 720px;
        }
        @media (min-width: 992px) {
            max-width: 960px;
        }
        @media (min-width: 1200px) {
            max-width: 1140px;
        }
        @media (min-width: 1500px) {
            max-width: 1424px;
        }
        width: 100%;
        padding-right: var(--bs-gutter-x, 1.2rem);
        padding-left: var(--bs-gutter-x, 1.2rem);
        margin-right: auto;
        margin-left: auto;
        .wrapper {
            width: 100%;
            @media (min-width: 992px) {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .itemWrapper {
                display: block;
                width: 100%;
                .item {
                    background-color: white;
                    margin-bottom: 15px;
                    position: relative;
                    @media (min-width: 768px) {
                        display: flex;
                        height: 120px;
                    }
                    .linkWrap {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        background-color: white;
                        .itemData {
                            background-color: #1c2e6d;
                            position: relative;
                            @media (min-width: 768px) {
                                flex: 0 0 200px;
                            }
                            @media (min-width: 992px) {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                flex: 0 0 150px;
                                width: 150px;
                            }
                            @media (min-width: 1200px) {
                                flex: 0 0 120px;
                                width: 120px;
                            }
                            @media (min-width: 1500px) {
                                flex: 0 0 213px;
                                width: 213px;
                            }
                            .mainImg {
                                width: 100%;
                                height: 100%;
                                .figureImage {
                                    width: 100%;
                                    height: 100%;
                                    img {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .itemInfo {
                        z-index: 2;
                        position: relative;
                        @media (min-width: 768px) {
                            flex: 1;
                        }
                        .itemInfoHeader {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 2px;
                            a {
                                padding-top: 5px;
                                h4 {
                                    display: inline-block;
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.93px;
                                    color: #9d9d9d;
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    margin-bottom: 9px;
                                    margin-right: 30px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                    text-decoration: none;
                                    @media (min-width: 992px) {
                                        font-size: 10px;
                                        line-height: 15px;
                                    }

                                    @media (min-width: 1200px) {
                                        font-size: 13px;
                                        line-height: 20px;
                                        white-space: nowrap;
                                    }
                                }
                                h2 {
                                    font-size: 18px;
                                    line-height: 20px;
                                    color: #191c38;
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    display: inline-block;
                                    max-width: 80%;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    max-height: 21px;
                                    @media (min-width: 992px) {
                                        font-size: 15px;
                                        line-height: 20px;
                                    }

                                    @media (min-width: 1200px) {
                                        font-size: 18px;
                                        line-height: 20px;
                                    }
                                }
                                span {
                                    color: #9d9d9d;
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    font-size: 12px;
                                    line-height: 17px;
                                    text-transform: uppercase;
                                }
                            }
                            .itemAction {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                z-index: 2;
                                background-color: white;
                                @media (min-width: 768px) {
                                    width: 150px;
                                }
                                @media (min-width: 992px) {
                                    flex: 0 0 120px;
                                    width: 100px;
                                }
                                .actionItem {
                                    width: 50%;
                                    text-align: end;
                                    margin-right: 10px;
                                    input {
                                        display: inline-block;
                                        width: 25px;
                                        height: 25px;
                                    }
                                }
                            }
                        }
                        .itemInfoDescription {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            color: #000000;
                            padding: 0 20px;
                            word-wrap: anywhere;
                        }
                    }
                }
                .itemActive {
                    border-right: 4px solid #da251d;
                    &:after {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        background-color: #da251d;
                        position: absolute;
                        right: -8px;
                        transform: translateY(-50%) rotate(45deg);
                        top: 50%;
                        border-top: 1px solid #da251d;
                        border-left: 1px solid #da251d;
                    }
                    @media (min-width: 768px) {
                        height: 200px;
                    }
                }
            }
            .itemWrapperActive {
                display: none;
                @media (min-width: 992px) {
                    display: block;
                    width: 100%;
                }
                .item {
                    background-color: white;
                    margin-bottom: 15px;
                    position: relative;
                    border-right: 4px solid white;
                    @media (min-width: 768px) {
                        display: flex;
                        height: 140px;
                        width: 110%;
                    }
                    @media (min-width: 1200px) {
                        display: flex;
                        height: 140px;
                        width: 98%;
                    }
                    .linkWrap {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        background-color: white;
                        .itemData {
                            background-color: #1c2e6d;
                            position: relative;
                            @media (min-width: 768px) {
                                flex: 0 0 200px;
                            }
                            @media (min-width: 992px) {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                flex: 0 0 150px;
                                width: 150px;
                            }
                            @media (min-width: 1200px) {
                                flex: 0 0 120px;
                                width: 120px;
                            }
                            @media (min-width: 1500px) {
                                flex: 0 0 213px;
                                width: 213px;
                            }
                            .mainImg {
                                width: 100%;
                                height: 100%;
                                .figureImage {
                                    width: 100%;
                                    height: 100%;
                                    text-align: center;
                                    img {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .itemInfo {
                        z-index: 2;
                        position: relative;
                        @media (min-width: 768px) {
                            flex: 1;
                        }
                        .itemInfoHeader {
                            display: flex;
                            justify-content: space-between;
                            a {
                                padding-top: 5px;
                                h4 {
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.93px;
                                    color: #9d9d9d;
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    margin-bottom: 9px;
                                    text-decoration: none;
                                    padding-left: 10px;
                                    padding-top: 20px;
                                    @media (min-width: 992px) {
                                        font-size: 10px;
                                        line-height: 15px;
                                    }

                                    @media (min-width: 1200px) {
                                        font-size: 13px;
                                        line-height: 20px;
                                        white-space: nowrap;
                                    }
                                }
                                h2 {
                                    font-size: 18px;
                                    line-height: 20px;
                                    color: #191c38;
                                    font-family: 'Barlow';
                                    font-weight: bold;

                                    @media (min-width: 992px) {
                                        font-size: 15px;
                                        line-height: 20px;
                                    }

                                    @media (min-width: 1200px) {
                                        font-size: 18px;
                                        line-height: 20px;
                                    }
                                }
                                span {
                                    color: #9d9d9d;
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    font-size: 12px;
                                    line-height: 17px;
                                    text-transform: uppercase;
                                }
                            }
                            .itemAction {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                z-index: 2;
                                background-color: white;
                                @media (min-width: 768px) {
                                    width: 150px;
                                }
                                @media (min-width: 992px) {
                                    flex: 0 0 120px;
                                    width: 100px;
                                }
                                .actionItem {
                                    width: 50%;
                                    text-align: end;
                                    margin-right: 10px;
                                    input {
                                        display: inline-block;
                                        width: 25px;
                                        height: 25px;
                                    }
                                }
                            }
                        }
                        .itemInfoDescription {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            color: #000000;
                            padding: 0 20px;
                            word-wrap: anywhere;
                        }
                    }
                }
                .itemActive {
                    border-right: 4px solid #da251d;
                }
            }
        }
    }
}