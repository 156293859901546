@font-face {
    font-family: 'Helvetica';
    src: url("../fonts/HelveticaRegular/Helvetica.eot");

    src: local('Helvetica'), url("../fonts/HelveticaRegular/Helvetica.woff2") format("woff2"),
        url("../fonts/HelveticaRegular/Helvetica.woff") format("woff"),
        url("../fonts/HelveticaRegular/Helvetica.ttf") format("truetype"),
        url("../fonts/HelveticaRegular/Helvetica.svg") format("svg");

    font-weight: normal;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: 'Helvetica';
    src: url("../fonts/HelveticaBold/Helvetica-Bold.eot");

    src: local('Helvetica'), url("../fonts/HelveticaBold/Helvetica-Bold.woff2") format("woff2"),
        url("../fonts/HelveticaBold/Helvetica-Bold.woff") format("woff"),
        url("../fonts/HelveticaBold/Helvetica-Bold.ttf") format("truetype"),
        url("../fonts/HelveticaBold/Helvetica-Bold.svg") format("svg");

    font-weight: bold;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: 'DIN';
    src: url("../fonts/DINBold/DINBold.eot");

    src: local('DIN'), url("../fonts/DINBold/DINBold.woff2") format("woff2"),
        url("../fonts/DINBold/DINBold.woff") format("woff"),
        url("../fonts/DINBold/DINBold.ttf") format("truetype"),
        url("../fonts/DINBold/DINBold.svg") format("svg");

    font-weight: bold;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: 'Barlow';
    src: url("../fonts/Barlow/Barlow.otf");
}

@font-face {
    font-family: 'BarlowSemi';
    src: url("../fonts/Barlow/Barlow.otf");
}

@font-face {
    font-family: 'BarlowBold';
    src: url("../fonts/Barlow/BarlowBold.otf");
}

$helvetica: 'Helvetica';
$din: 'DIN';