.layout-video {
    padding-bottom: 75px;
    a {
        color: $white;
        text-align: center;
        display: block;
        margin: 0 auto;
        text-decoration: none;
        font-family: 'Barlow';
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
    }
    .layout-wrapper {
        .layout-item {
            margin-bottom: 15px;
            @media (min-width: 768px) {
                display: flex;
                width: 100%;
            }
            .layout-item-img {
                @media (min-width: 768px) {
                    flex: 0 0 213px;
                    width: 213px;
                }
                figure {
                    margin-bottom: 0;
                    height: 100%;
                    img {
                        height: 100%;
                    }
                }
            }
            .layout-item-info {
                background-color: $white;
                padding: 22px 18px 16px 18px;
                @media (min-width: 768px) {
                    flex: 1;
                    padding: 15px 18px 15px 18px;
                }
                @media (min-width: 992px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                @media (min-width: 1200px) {
                    padding: 42px 89px 37px 24px;
                }
                .layout-item-info-data {
                    margin-bottom: 20px;
                    @media (min-width: 992px) {
                        flex: 0 0 60%;
                        width: 60%;
                        margin-bottom: 0;
                    }
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;

                        @media (min-width: 768px) {
                            font-size: 13px;
                            line-height: 17px;
                        }
                        @media (min-width: 992px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                }
                .layout-item-info-action {
                    display: flex;
                    align-items: center;
                    @media (min-width: 992px) {
                    }
                    span {
                        display: block;
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 17px;
                        text-transform: uppercase;
                        &:first-of-type {
                            margin-right: 10px;
                        }
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        display: inline-block;
                        margin-left: 63px;
                        svg {
                            fill: $danger;
                        }
                    }
                }
            }
        }
    }
}
