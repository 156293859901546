.form-group {
    margin-bottom: 1.5rem;
    @media (min-width: 768px) {
        margin-bottom: 3rem;
    }
}

.input-group {
    display: flex;
    width: 100%;
    min-width: 1px;
    input {
        border-right: none !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        flex: 0 1 100%;
        min-width: 1px;
    }
    .input-group-append {
        .btn {
            height: 100%;
            border-radius: 0;
            font-size: 1.3rem;
        }
        .text {
            height: 100%;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            border: 2px solid #d9d9d9; 
            border-left: none; 
            border-radius: 0 9px 9px 0;
            padding: 0 1rem;
            font-size: 1.4rem;
            color: #a6a6a6;
            &:before {
                content: "";
                width: 1px;
                height: 60%;
                top: 20%;
                background-color: #a6a6a6;
                left: 0;
                position: absolute;
            }
        }
    }
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-control {
    height: auto;
    line-height: 4.5rem;
    transition: 0.2s linear;
    flex: 0 1 100%;
    max-width: 100%;
    width: 100%;
    display: block;
    font-size: 1.4rem;
    border: none;
    border-radius: 9px;
    padding: 0 15px;
    background-color: #fff;
    border: 2px solid #d9d9d9;
    font-weight: 600;
    color: #254594;
    @media (min-width: 768px) {
        line-height: 5rem;
        padding: 0 2.5rem;
        font-size: 1.6rem;
    }
    &::placeholder {
        opacity: 1;
        color: #a6a6a6;
        font-weight: 400;
        font-size: 1.4rem;
        @media (min-width: 768px) {
            font-size: 1.6rem;
        }
    }
    &[type="number"] {
        appearance: textfield;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        @media (min-width: 768px) {
            text-align: right;
        }
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &.is-invalid {
        border: 1px solid #c92327;
        box-shadow: 0 0 3px #c92327;
    }
    &.is-valid {
        border: 1px solid #04d967;
        box-shadow: 0 0 3px #04d967;
    }

    &:focus {
        outline: none;
    }
}

.invalid-feedback {
    padding: 1rem 0 0;
    color: #c92327;
}
.error {
    color: #c92327;
    font-size: 1.4rem;
    font-style: italic;
}
input[type="radio"],
input[type="checkbox"] {
    display: none;
}
.label-text {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.26px;
    color: #a6a6a6;
    @media (min-width: 768px) {
        font-size: 1.6rem;
    }
}
.fake-radio,
.fake-checkbox {
    width: 22px;
    flex: 0 0 22px;
    height: 22px;
    border-radius: 60%;
    margin-right: 1rem;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #254594;
    cursor: pointer;
    &:after {
        content: "";
       // font-family: "Font Awesome 5 Free";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 16px;
        font-size: 1.3rem !important;
        color:#254594;
    }
}

.fake-checkbox {
    border-radius: 4px !important;
}
input[type="radio"]:checked + .fake-radio,
input[type="checkbox"]:checked + .fake-checkbox {
    &:after {
        background-color: #fff;;
        content: "\2713";
    }
}

input[type="radio"]:checked + .fake-radio {
    &:after {
        background-color: #fff;
        content: "";
    }
}

.radio-label {
    cursor: pointer;
    margin-bottom: 0;
}

// .fake-checkbox::after {
//     font-family: "Font Awesome 5 Free";
// }
// .fake-radio::after {
//     font-family: "Font Awesome 5 Free";
// }
// input[type="checkbox"]:checked + .fake-checkbox::after {
//     background-image: url(img/check.png);
//     color: #254594;
//     font-weight: bolder;
// }
// input[type="radio"]:checked + .fake-radio::after {
//     background-image: url(img/check.png);
//     color: #254594;
//     font-weight: bolder;
// }
// input[type="checkbox"]:checked ~ .checkbox-text {
//     color: #254594 !important;
// }
// input[type="radio"]:checked ~ .radio-text {
//     color: #254594 !important;
// }
