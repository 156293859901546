footer{
    display: none;
    background-color: $white;
    @media (min-width: 992px) {
        display: block;
        padding: 15px 0;
    }
    .footer-info{
        color: $info;
        font-family: 'Barlow';
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 0px;
        a{
            color: #2A50A6;
        }
    }
}