.fixedTop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.langButton {
    border-radius: 0!important;
    padding-top: 5px;
}

.mainMenu {
    @media(max-width: 768px) {
        margin-top: 20px!important;
    }
}

.topLinks {
    display: flex;
    width: 100%;
    justify-content: center;
    transform: translate(0, 10px);
    .topLink {
        color: white;
        margin-right: 10px;
        margin-left: 10px;
        text-decoration: none;
        img {
            margin-left: 5px;
            margin-right: 5px;
            width: 25px;
            height: 25px;
        }
        &.active:first-of-type{
            border-bottom: 2px solid;
            border-image: linear-gradient(to right, #da251d 60%, transparent 50%) 100% 1;
        }
        &.active:last-of-type {
            border-bottom: 2px solid;
            border-image: linear-gradient(to left, #da251d 40%, transparent 50%) 100% 1;
        }
    }
    @media (min-width: 768px) {
        display: none;
    }
}