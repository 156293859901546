header {
    background-color: $info;
    padding: 20px 0;
    @media (min-width: 768px) {
        padding: 0;
    }
    .navbar {
        padding: 0;
        .nabvar-brand {
            display: block;
            max-width: 114px !important;
        }
        .navbar-toggler {
            margin-left: auto;
            &:focus {
                box-shadow: none;
            }
            span {
                display: block;
                width: 35px;
                height: 3px;
                background-color: $white;
                margin-bottom: 5px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        #main-menu {
            @media (min-width: 768px) {
                justify-content: center;
            }
            .navbar-nav {
                padding: 0 30px;
                @media (min-width: 768px) {
                    flex: 1;
                    justify-content: center;
                    padding: 0;
                }
                .nav-item {
                    margin-bottom: 10px;
                    border-bottom: 4px solid transparent;
                    @media (min-width: 768px) {
                        margin-bottom: 0;
                        margin-right: 25px;
                        padding: 25px 0;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                    &.active {
                        border-bottom: 4px solid $danger;
                        .nav-link {
                            color: $white;
                            span {
                                svg {
                                    margin-bottom: 5px;
                                    fill: $white;
                                }
                            }
                        }
                    }
                    @media (min-width: 992px) {
                        margin-right: 53px;
                    }
                    .nav-link {
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        display: inline-block;
                        justify-content: center;
                        @media (min-width: 768px) {
                            justify-content: flex-start;
                        }
                        span {
                            display: inline-block;
                            width: 16px;
                            height: 24px;
                            margin-right: 10px;
                            svg {
                                margin-bottom: 5px;
                                fill: $secondary;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline;
                        }
                    }
                }
            }
        }
        .dropdown {
            background-color: transparent !important;
            @media (min-width: 768px) {
                display: flex;
                align-items: center;
                order: 2;
            }
            .dropdown-toggle {
                @media (min-width: 768px) {
                    display: flex;
                    align-items: center;
                }
                &::after {
                    color: $white;
                }
                &:focus {
                    box-shadow: none;
                }
                span {
                    &:first-of-type {
                        display: none;
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 28px;
                        @media (min-width: 768px) {
                            display: block;
                        }
                    }
                    &:last-of-type {
                        @media (min-width: 768px) {
                            display: none;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                        svg {
                            fill: $white;
                        }
                    }
                }
            }
            .dropdown-menu {
                width: 270px;
                background-color: $primary;
                padding: 20px;
                left: unset;
                right: 0;
                li {
                    margin-bottom: 10px;
                    .dropdown-item {
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        &:hover {
                            background-color: rgba($info, 0.5);
                        }
                    }

                    span {
                        display: block;
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        padding: 0.25rem 1rem;
                    }
                }
            }
        }
    }
}
