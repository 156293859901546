@import "./_partials/_overide-bootstrap.scss";
@import "./bootstrap/scss/bootstrap.scss";

@import "_partials/_fonts.scss";

@import "_partials/_search.scss";
@import "_partials/layout.scss";

@import "_partials/_general.scss";
@import "_partials/_header.scss";
@import "_partials/_footer.scss";

@import "news/_grid.scss";

@import "photo/_layout-photo.scss";
@import "photo/_grid-photo.scss";

@import "video/_grid-video.scss";
@import "video/_layout-video.scss";

@import "fono/_grid.scss";
@import "fono/_layout-fono.scss";

@import "subscription/_profile.scss";

.active-menu {
    display: block;
    top: 40px;
}