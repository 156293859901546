.layout {
    padding-bottom: 75px;
    position: relative;
    .container {
        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1140px;
        }

        @media (min-width: 1500px) {
            max-width: 1424px;
        }

        width: 100%;
        padding-right: var(--bs-gutter-x, 1.2rem);
        padding-left: var(--bs-gutter-x, 1.2rem);
        margin-right: auto;
        margin-left: auto;

        .wrapper {
            width: 100%;
            display: flex;
            .itemWrapper{
                width: 100%;
                @media (min-width: 576px) {
                    display: grid;
                    grid-template-columns: 50% 50%;
                }
                @media (min-width: 992px) {
                    grid-template-columns: 25% 25% 25% 25%;
                }
                @media (min-width: 1500px) {
                    grid-template-columns: 20% 20% 20% 20% 20%;
                }
                .item {
                    border: 3px solid white;
                    background-color: white;
                    margin-bottom: 20px;
                    margin-right: 0;
                    cursor: pointer;
                    @media (min-width: 576px) {
                        margin-right: 20px;
                        &:nth-child(2n) {
                            margin-right: 0;
                        }
                    }
                    @media (min-width: 992px) {
                        &:nth-child(2n) {
                            margin-right: 20px;
                        }
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                    }
                    @media (min-width: 1500px) {
                        &:nth-child(4n) {
                            margin-right: 20px;
                        }
                        &:nth-child(5n) {
                            margin-right: 0;
                        }
                    }
                    .mainImg{
                        margin-top: 10px;
                        min-height: 200px;
                        max-height: 200px;
                        max-width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        overflow: hidden;
                
                        figure {
                            margin: 7rem 0 1rem;
                        }
                    }
                    .info {
                        background-color: white;
                    }
                
                    .infoData {
                        padding: 15px 15px;
                        max-height: 80px;
                        min-height: 80px;
                        h4 {
                            text-align: center;
                            font-size: 1.5rem;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                
                        h3 {
                            text-align: center;
                            font-size: 1.2rem;
                            text-transform: uppercase;
                        }
                
                        h2 {
                            text-align: center;
                            font-size: 2.0rem;
                            color: black;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                
                    .action {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;
                        .actionSection {
                            display: flex;
                            justify-content: right;
                            .actionItem{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 6px;
                                input {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
                .itemActive {
                    border: 3px solid #da251d;
                }
            }
            .itemWrapperActive{
                display: grid;
                grid-template-columns: auto auto;
                max-width: 40%;
                @media (max-width: 991px) {
                    display: none;
                }

                .item {
                    border: 3px solid white;
                    background-color: white;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    cursor: pointer;
                    .mainImg{
                        margin-top: 10px;
                        min-height: 200px;
                        max-height: 200px;
                        max-width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        overflow: hidden;
                
                        figure {
                            margin: 7rem 0 1rem;
                        }
                    }
                    .info {
                        background-color: white;
                    }
                
                    .infoData {
                        padding: 15px 15px;
                        max-height: 80px;
                        min-height: 80px;
                        h4 {
                            text-align: center;
                            font-size: 1.5rem;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                
                        h3 {
                            text-align: center;
                            font-size: 1.2rem;
                            text-transform: uppercase;
                        }
                
                        h2 {
                            text-align: center;
                            font-size: 2.0rem;
                            color: black;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                
                    .action {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;
                        .actionSection {
                            display: flex;
                            justify-content: right;
                            .actionItem{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 6px;
                                input {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }

                .itemActive {
                    border: 3px solid #da251d;
                }
            }
        }
    }
    &.singlePhoto {
        @media (max-width: 768px) {
            transform: translate(0, 60px);
        }
    }
}