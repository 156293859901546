.container{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: calc(100% + 153px);
    transform: translateY(-153px);
    width: 100%;
    background-color: transparentize($color: #000000, $amount: 0.5);
    z-index: 5;
    @media(max-width: 768px) {
        transform: translateY(-161px);
        height: calc(100% + 160px);
    }

    .text{
        position: fixed;
        top: 300px;
        opacity: 1;
        color: white;
        background-color: #1c2e6d;
        padding: 10px;
        border: 1px solid white;
        border-radius: 2px;
    }
}