.grid-layout-fono {
    padding-bottom: 75px;
    position: relative;
    .grid-layout-wrapper {
        @media (max-width: 768px) {
            margin-top: 80px;
        }
        @media (min-width: 992px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .grid-item-wrapper {
            @media (min-width: 992px) {
                flex: 0 0 calc(100%);
                width: calc(100%);
            }
            a {
                color: $white;
                text-align: center;
                display: block;
                margin: 0 auto;
                text-decoration: none;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 20px; 
                line-height: 25px;
                padding: 10px 0;
            }
            .layout-item {
                margin-bottom: 15px;
                border-right: 4px solid transparent;
                position: relative;
                background: white;
                display: flex;
                justify-content: space-between;
                min-height: 157px;
                max-height: 157px;
                &.active{
                    border-right: 4px solid $danger;
                    &:after {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        background-color: $danger;
                        position: absolute;
                        right: -8px;
                        transform: translateY(-50%) rotate(45deg);
                        top: 50%;
                        border-top: 1px solid $danger;
                        border-left: 1px solid $danger;
                    }
                }
                @media (max-width: 768px) {
                    max-height: 250px;
                }
                @media (min-width: 768px) {
                    display: flex;
                    width: 100%;
                    min-height: 158px;
                    max-height: 158px;
                }
                .layout-item-data {
                    background-color: $blue;
                    padding: 45px 17px 15px 17px;
                    position: relative;
                    @media (max-width: 768px) {
                        display: none;
                    }
                    @media (min-width: 768px) {
                        flex: 0 0 213px;
                        width: 213px;
                    }
                    @media (min-width: 992px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 0 0 150px;
                        width: 150px;
                        padding: 15px 0 15px 15px;
                    }
                    @media (min-width: 1200px) {
                        flex: 0 0 120px;
                        width: 120px;
                        padding: 10px 0 10px 10px;
                    }
                    @media (min-width: 1500px) {
                        padding: 22px 0 16px 18px;
                        flex: 0 0 213px;
                        width: 213px;
                    }
                    span {
                        color: $white;
                        display: block;
                        font-family: 'Barlow';
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        text-transform: uppercase;
                    }
                    p {
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 0;
                        text-transform: uppercase;
                        padding-top: 25px;
                    }
                }
                .layout-item-date {
                    display: none;
                    @media (max-width: 768px) {
                        display: block;
                        position: absolute;
                        background-color: #1c2e6d;
                        z-index: 3;
                        color: white;
                        font-family: 'Barlow';
                        width: calc(100% + 4px);
                        max-height: 35px;
                        padding-left: 10px;
                    }
                }
                .layout-item-info {
                    background-color: $white;
                    padding: 22px 10px 16px 10px;
                    z-index: 2;
                    position: relative;
                    @media (max-width: 768px) {
                        padding-top: 35px;
                        padding-bottom: 30px;
                    }
                    @media (min-width: 768px) {
                        flex: 1;
                    }
                    @media (min-width: 992px) {
                        padding: 31px 36px 28px 10px;
                    }
                    @media (min-width: 1200px) {
                        padding: 31px 0 28px 10px;
                    }
                    h4 {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.93px;
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 9px;
                        @media (min-width: 992px) {
                            font-size: 10px;
                            line-height: 15px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 13px;
                            line-height: 20px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 15vw;
                        }
                    }
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;
                        @media (min-width: 992px) {
                            font-size: 15px;
                            line-height: 20px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                    span {
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                    div h2,h4,span {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        margin-right: 15px;
                    }
                }
                .layout-item-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    div {
                        padding-right: 8px;
                        padding-bottom: 8px;
                    }
                }
            }
        }
        .grid-item-wrapper.active{
            display: none;
            @media (min-width: 992px){
                display: block;
                flex: 0 0 calc(40% - 24px / 2);
                width: calc(40% - 24px / 2);
            }
            .layout-item {
                max-height: 157px;
                min-height: 157px;
                .layout-item-info {
                    background-color: $white;
                    padding: 22px 10px 16px 10px;
                    width: 256px;
                    z-index: 2;
                    position: relative;
                    @media (min-width: 768px) {
                        flex: 1;
                    }
                    @media (min-width: 992px) {
                        padding: 31px 36px 28px 10px;
                    }
                    @media (min-width: 1200px) {
                        padding: 31px 0 28px 10px;
                    }
                    h4 {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.93px;
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 9px;
                        @media (min-width: 992px) {
                            font-size: 10px;
                            line-height: 15px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 13px;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                    }
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;
                        @media (min-width: 992px) {
                            font-size: 15px;
                            line-height: 20px;
                            max-height: 70px;
                            max-width: 300px;
                            text-overflow: ellipsis;
                        }
                        @media (min-width: 1200px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                    span {
                        color: $secondary;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                    div h2,h4,span {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        margin-right: 15px;
                    }
                }
                .layout-item-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    div {
                        padding-right: 8px;
                        padding-bottom: 8px;
                    }
                }
            }
        }
        .grid-sidebar-wrapper {
            display: flex;
            height: 93vh;
            width: 100%;
            position: fixed;
            left: 0px;
            background-color: white;
            @media (min-width: 992px) {
                position: sticky;
                flex: 0 0 60%;
                width: 60%;
                height: 88vh;
                top: 100px;
            }
            .grid-sidebar-main-wrapper {
                padding: 15px 15px 0px 15px;
                max-width: 98%;
                min-width: 98%;
                margin-inline: auto;
                position: relative;
                @media (min-width: 1200px) {
                    padding: 15px 15px 15px 15px;
                }
                .close{
                    position: absolute;
                    top: 10px;
                    right: 0px;
                    cursor: pointer;
                    svg {
                        fill: #9d9d9d;
                    }
                }
                .grid-sidebar-header {
                    @media (min-width: 576px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    &>div:last-child {
                        margin-right: 7px;
                    }
                    span {
                        display: block;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 17px;
                        color: $info;
                        margin-bottom: 20px;
                        @media (min-width: 576px) {
                            margin-bottom: 0;
                        }
                        &:first-of-type {
                            margin-right: 15px;
                        }
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        &:first-of-type {
                            border: 1px solid rgba(25, 28, 56, 0.25);
                            display: flex;
                            align-items: center;
                            padding: 1px 15px;
                            margin-right: 17px;
                        }
                        &:last-of-type {
                            background-color: $primary;
                            color: $white;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 28px;
                            padding: 5px 15px;
                            display: flex;
                            align-items: center;
                            svg {
                                fill: $white;
                                margin-right: 11px;
                            }
                        }
                    }
                }
                .grid-sidebar-content {
                    height: 73vh;
                    overflow-y: scroll;
                    margin-top: 5px;
                    padding: 0px 10px;
                    max-height: 92%;
                    @media(min-width: 992px){
                        height: 88vh;
                        max-height: 92%;
                    }
                    .grid-sidebar-content-top {
                        padding: 0px 7px 10px;
                        h4 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.93px;
                            color: #9d9d9d;
                            margin-bottom: 6px;
                            text-transform: uppercase;
                            @media (min-width: 450px) {
                                white-space: nowrap;
                            }
                        }
                        h2 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 26px;
                            color: #000000;
                            @media (min-width: 576px) {
                                font-size: 28px;
                                line-height: 34px;
                            }
                        }
                    }
                    .grid-sidebar-content-middle {
                        border-top: 1px solid #eeeeee;
                        color: black;
                        padding-top: 17px;
                        p {
                            font-family: 'Barlow';
                            font-weight: normal;
                            color: black;
                            font-size: 16px;
                            line-height: 24px;
                            white-space: pre-wrap;
                        
                        }
                        a {
                            display: block;
                            text-align: end;
                            color: $primary;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 14px;
                            text-decoration: none;
                            padding-top: 20px;
                            margin-bottom: 15px;
                            @media (min-width: 450px) {
                                padding-top: 0;
                            }
                            svg {
                                fill: $primary;
                                margin-right: 5px;
                            }
                        }
                    }
                    .grid-sidebar-content-bottom {
                        padding: 20px 25px 10px;
                        border-top: 1px solid #eeeeee;
                        div {
                            padding: 3px 0px;
                            border-bottom: #a8a8a8 1px dashed;
                            span {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 26px;
                                letter-spacing: -0.22px;
                                color: #9d9d9d;
                            }
                        }
                    }
                }
            }
        }
        .grid-scroll-wrapper {
            display: flex;
            height: 93vh;
            width: 100%;
            position: fixed;
            left: 0px;
            background-color: white;
            @media (min-width: 992px) {
                position: sticky;
                flex: 0 0 60%;
                width: 60%;
                height: 88vh;
                top: 100px;
            }
            .grid-sidebar-main-wrapper {
                padding: 15px 15px 0px 15px;
                max-width: 98%;
                min-width: 98%;
                margin-inline: auto;
                position: relative;
                @media (min-width: 1200px) {
                    padding: 15px 15px 15px 15px;
                }
                .close{
                    position: absolute;
                    top: 10px;
                    right: 0px;
                    cursor: pointer;
                    svg {
                        fill: #9d9d9d;
                    }
                }
                .grid-sidebar-header {
                    @media (min-width: 576px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    &>div:last-child {
                        margin-right: 7px;
                    }
                    span {
                        display: block;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 17px;
                        color: $info;
                        margin-bottom: 20px;
                        @media (min-width: 576px) {
                            margin-bottom: 0;
                        }
                        &:first-of-type {
                            margin-right: 15px;
                        }
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        &:first-of-type {
                            border: 1px solid rgba(25, 28, 56, 0.25);
                            display: flex;
                            align-items: center;
                            padding: 1px 15px;
                            margin-right: 17px;
                        }
                        &:last-of-type {
                            background-color: $primary;
                            color: $white;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 28px;
                            padding: 5px 15px;
                            display: flex;
                            align-items: center;
                            svg {
                                fill: $white;
                                margin-right: 11px;
                            }
                        }
                    }
                }
                .grid-sidebar-content {
                    height: 73vh;
                    margin-top: 5px;
                    overflow-y: scroll;
                    padding: 0px 10px;
                    max-height: 92%;
                    @media(min-width: 992px){
                        height: 88vh;
                        max-height: 92%;
                    }
                    .grid-sidebar-content-top {
                        padding: 0px 7px 10px;
                        h4 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.93px;
                            color: #9d9d9d;
                            margin-bottom: 6px;
                            text-transform: uppercase;
                            @media (min-width: 450px) {
                                white-space: nowrap;
                            }
                        }
                        h2 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 26px;
                            color: #000000;
                            @media (min-width: 576px) {
                                font-size: 28px;
                                line-height: 34px;
                            }
                        }
                    }
                    .grid-sidebar-content-middle {
                        border-top: 1px solid #eeeeee;
                        color: black;
                        padding-top: 17px;
                        p {
                            color: black;
                            font-family: 'Barlow';
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            white-space: pre-wrap;
                        }
                        a {
                            display: block;
                            text-align: end;
                            color: $primary;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 14px;
                            text-decoration: none;
                            padding-top: 20px;
                            margin-bottom: 15px;
                            @media (min-width: 450px) {
                                padding-top: 0;
                            }
                            svg {
                                fill: $primary;
                                margin-right: 5px;
                            }
                        }
                    }
                    .grid-sidebar-content-bottom {
                        padding: 20px 25px 10px;
                        border-top: 1px solid #eeeeee;
                        div {
                            padding: 3px 0px;
                            border-bottom: #a8a8a8 1px dashed;
                            span {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 26px;
                                letter-spacing: -0.22px;
                                color: #9d9d9d;
                            }
                        }
                    }
                }
            }
        }
    }
    .loader{
        width: 100%;
        display: flex;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
    }
}
