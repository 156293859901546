.login {
    height: 100vh;
    @media (min-width: 992px) {
        display: flex;
    }
    .login-wrapper {
        background: linear-gradient(180deg, #2a50a6 0%, #1c2e6d 100%);
        height: 100vh;
        @media (min-width: 992px) {
            order: 2;
            flex: 0 0 40%;
            width: 40%;
        }
        .login-form-wrapper {
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .logo-title-wrapper {
                max-width: 452px;
                width: 100%;
                margin: 0 auto;
                @media (max-width: 764px) {
                    padding-left: 20px;
                }
                .logo{
                    height: 30px;
                    width: 92px;
                    margin-bottom: 20px;
                }

                h2 {
                    position: relative;
                    font-family: 'BarlowSemi';
                    font-weight: bold;
                    font-size: 44px;
                    line-height: 40px;
                    color: $white;
                    text-align: left;
                    margin-top: 30px;
                }
            }
            
            .login-form {
                padding: 55px 15px 0 15px;
                max-width: 452px;
                width: 100%;
                margin: 0 auto;
                @media (min-width: 992px) {
                    padding: 55px 20px 0 20px;
                }
                @media (min-width: 1500px) {
                    padding: 0;
                    padding-top: 55px;
                }
                .form-group {
                    margin-bottom: 35px;
                    label {
                        font-family: 'BarlowSemi';
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 26px;
                        letter-spacing: 2px;
                        color: $white;
                        margin-bottom: 17px;
                        text-transform: uppercase;
                    }
                    .form-control {
                        height: 60px;
                        border-radius: 0;
                        border: 2px solid $white;
                        background-color: transparent;
                        color: $white;
                        font-family: 'Barlow';
                        font-weight: normal;
                        font-size: 16px;
                        padding-left: 20px;
                        &::placeholder {
                            color: $white;
                            font-family: 'Barlow';
                            font-weight: normal;
                            font-size: 16px;
                            opacity: 0.65;
                        }
                        &:focus::placeholder{
                            color: transparent;
                        }
                    }
                }
                .btn-login-wrapper {
                    text-align: center;
                    .btn-login {
                        background-color: $white;
                        border-radius: 0;
                        color: #2a50a6;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        border: none;
                        padding: 12px 35px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .login-data {
        display: none;
        background: linear-gradient(180deg, rgba(42, 80, 166, 0.14) 0%, #020c30 100%), url(../../img/news.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        @media (min-width: 992px) {
            flex: 0 0 60%;
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .login-data-wrapper {
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            padding-left: 50px;
            @media(min-width: 1200px){
                padding-left: 100px;
            }
            .login-logo {
                display: block;
                max-width: 100px;
            }
            .login-info {
                padding-bottom: 50px;
                @media(min-width: 1200px){
                    padding-bottom: 100px;
                }
                @media(min-width: 1500px){
                    padding-bottom: 150px;
                }
                h2 {
                    font-size: 44px;
                    line-height: 54px;
                    font-family: 'Barlow';
                    font-weight: bold;
                    color: $white;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 18px;
                    line-height: 26px;
                    font-family: 'Barlow';
                    font-weight: normal;
                    color: $white;
                    margin-bottom: 78px;
                }
                .login-numbers {
                    display: flex;
                    align-items: center;
                    .login-numbers-item {
                        padding: 0 30px 0 20px;
                        border-right: 1px solid rgba(235,241,255,0.24);
                        &:first-of-type{
                            padding-left: 0;
                        }
                        p {
                            margin-bottom: 0;
                            font-family: 'Barlow';
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 28px;
                            color: #7393d9;
                            &:first-of-type {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}


