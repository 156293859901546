.wrapper {
    display: flex;
    height: 93vh;
    width: 100%;
    position: fixed;
    left: 0px;
    background-color: white;
    @media (min-width: 992px) {
        height: 73vh;
        position: sticky;
        top: 240px;
        flex: 0 0 55%;
        max-width: 55%;
        margin-left: 5%;
        left: 5px;
    }
    @media (min-width: 1200px) {
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 0;
    }
    .mainWrapper {
        padding: 15px 15px 15px 15px;
        max-width: 98%;
        min-width: 98%;
        margin-inline: auto;
        position: relative;
        .close {
            position: absolute;
            top: 10px;
            right: 0px;
            cursor: pointer;
            svg {
                fill: #9d9d9d;
            }
        }
        .header {
            @media (min-width: 576px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            span {
                display: block;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 16px;
                line-height: 17px;
                color: #191c38;
                margin-bottom: 20px;
                @media (min-width: 576px) {
                    margin-bottom: 0;
                }
                &:first-of-type {
                    margin-right: 15px;
                }
            }
            a {
                display: block;
                text-decoration: none;
                &:first-of-type {
                    border: 1px solid rgba(25, 28, 56, 0.25);
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    margin-right: 7px;
                }
                &:last-of-type {
                    background-color: #254594;
                    color: #ffffff;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 15px;
                    display: flex;
                    justify-content: center;
                    svg {
                        fill: #ffffff;
                        margin-right: 11px;
                    }
                }
            }
        }
        .content {
            height: 77vh;
            overflow-y: scroll;
            margin-top: 5px;
            max-height: 92%;
            @media (min-width: 992px) {
                height: 93%;
            }
            .contentTop > div {
                margin-inline: auto;
            }
            .contentTop {
                padding: 0px 7px 10px;
                h4 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.93px;
                    color: #9d9d9d;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    @media (min-width: 450px) {
                        white-space: nowrap;
                    }
                }
                h2 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    color: #000000;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media (min-width: 576px) {
                        font-size: 28px;
                        line-height: 34px;
                    }
                }
                figure {
                    &::after {
                        padding-top: 66%;
                    }
                }
            }
            .contentMiddle {
                padding: 38px 7px 10px;
                border-top: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                color: #000000;
                overflow-wrap: break-word;
                p {
                    font-family: 'Barlow';
                    font-weight: normal;
                    color: #254594;
                    font-size: 16px;
                    line-height: 24px;
                    white-space: pre-wrap;
                }
            }
            .contentBottom {
                padding: 20px 25px 10px;
                div {
                    padding: 3px 0px;
                    border-bottom: #a8a8a8 1px dashed;
                    span {
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 26px;
                        letter-spacing: -0.22px;
                        color: #9d9d9d;
                    }
                }
            }
            .contentSlider {
                padding: 5px 7px 10px;
                position: relative;
                p {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.2px;
                    color: #4065b8;
                    margin-bottom: 20px;
                    text-align: center;
                    @media (min-width: 576px) {
                        text-align: start;
                    }
                }
                .sliderWrapper {
                    display: flex;
                    overflow-x: auto;
                    .sliderItem {
                        flex: 0 0 37%;
                        margin-right: 20px;
                        margin-bottom: 0;
                        @media (min-width: 576px) {
                            flex: 0 0 22%;
                        }
                        @media (min-width: 992px) {
                            flex: 0 0 37%;
                        }
                        @media (min-width: 1200px) {
                            flex: 0 0 26%;
                        }
                        &:last-of-type {
                            margin-right: 0 !important;
                        }
                        div {
                            text-align: center;
                            figure {
                                margin-bottom: 9px;
                                img {
                                    object-fit: cover;
                                    height: 180px;
                                }
                            }
                            p {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 10px;
                                line-height: 13px;
                                color: #254594;
                                margin-bottom: 0;
                                @media (min-width: 576px) {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
                .sliderNavigation { 
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    a {
                        text-decoration: none;
                        &.prev {
                            position: absolute;
                            left: -4%;
                        }
                        &.next {
                            position: absolute;
                            right: -4%;
                        }
                    }
                }
            }
        }
    }
}

.scrollWrapper {
    display: flex;
    height: 93vh;
    width: 100%;
    position: fixed;
    left: 0px;
    background-color: white;
    @media (min-width: 992px) {
        position: sticky;
        height: 88vh;
        top: 100px;
        flex: 0 0 55%;
        max-width: 55%;
        margin-left: 5%;
        left: 5px;
    }
    @media (min-width: 1200px) {
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 0;
    }
    .mainWrapper {
        padding: 15px 15px 15px 15px;
        max-width: 98%;
        min-width: 98%;
        margin-inline: auto;
        position: relative;
        .close {
            position: absolute;
            top: 10px;
            right: 0px;
            cursor: pointer;
            svg {
                fill: #9d9d9d;
            }
        }
        .header {
            @media (min-width: 576px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            span {
                display: block;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 16px;
                line-height: 17px;
                color: #191c38;
                margin-bottom: 20px;
                @media (min-width: 576px) {
                    margin-bottom: 0;
                }
                &:first-of-type {
                    margin-right: 15px;
                }
            }
            a {
                display: block;
                text-decoration: none;
                &:first-of-type {
                    border: 1px solid rgba(25, 28, 56, 0.25);
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    margin-right: 7px;
                }
                &:last-of-type {
                    background-color: #254594;
                    color: #ffffff;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 15px;
                    display: flex;
                    justify-content: center;
                    svg {
                        fill: #ffffff;
                        margin-right: 11px;
                    }
                }
            }
        }
        .content {
            height: 77vh;
            overflow-y: scroll;
            margin-top: 5px;
            max-height: 92%;
            @media (min-width: 992px) {
                max-height: 92%;
                height: 93%;
            }
            .contentTop > div {
                margin-inline: auto;
            }
            .contentTop {
                padding: 0px 7px 10px;
                h4 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.93px;
                    color: #9d9d9d;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    @media (min-width: 450px) {
                        white-space: nowrap;
                    }
                }
                h2 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    color: #000000;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media (min-width: 576px) {
                        font-size: 28px;
                        line-height: 34px;
                    }
                }
                figure {
                    &::after {
                        padding-top: 66%;
                    }
                }
            }
            .contentMiddle {
                padding: 38px 7px 10px;
                border-top: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                color: #000000;
                overflow-wrap: break-word;
                p {
                    font-family: 'Barlow';
                    font-weight: normal;
                    color: #254594;
                    font-size: 16px;
                    line-height: 24px;
                    white-space: pre-wrap;
                }
            }
            .contentBottom {
                padding: 20px 25px 10px;
                div {
                    padding: 3px 0px;
                    border-bottom: #a8a8a8 1px dashed;
                    span {
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 26px;
                        letter-spacing: -0.22px;
                        color: #9d9d9d;
                    }
                }
            }
            .contentSlider {
                padding: 5px 7px 10px;
                position: relative;
                p {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.2px;
                    color: #4065b8;
                    margin-bottom: 20px;
                    text-align: center;
                    @media (min-width: 576px) {
                        text-align: start;
                    }
                }
                .sliderWrapper {
                    display: flex;
                    overflow-x: auto;
                    .sliderItem {
                        flex: 0 0 37%;
                        margin-right: 20px;
                        margin-bottom: 0;
                        @media (min-width: 576px) {
                            flex: 0 0 22%;
                        }
                        @media (min-width: 992px) {
                            flex: 0 0 37%;
                        }
                        @media (min-width: 1200px) {
                            flex: 0 0 26%;
                        }
                        &:last-of-type {
                            margin-right: 0 !important;
                        }
                        div {
                            text-align: center;
                            figure {
                                margin-bottom: 9px;
                                img {
                                    object-fit: cover;
                                    height: 180px;
                                }
                            }
                            p {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 10px;
                                line-height: 13px;
                                color: #254594;
                                margin-bottom: 0;
                                @media (min-width: 576px) {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
                .sliderNavigation { 
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    a {
                        text-decoration: none;
                        &.prev {
                            position: absolute;
                            left: -4%;
                        }
                        &.next {
                            position: absolute;
                            right: -4%;
                        }
                    }
                }
            }
        }
    }
}