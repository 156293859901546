.canvas {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 5; /* Stay on top */
    top: 78;
    left: 0;
    background-color: #254594;
    color: white;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 5px; /* Place content 60px from the top */
    transition: 0.2s; /* 0.2 second transition effect to slide in the sidenav */
    a {
        cursor: pointer;
        padding: 5px;
    }
}

.filters {
    margin-top: 20px;
    .categories {
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
        .item {
            max-height: 38px!important;
            border-radius: 0!important;
            width: 330px;
            white-space: nowrap;
        }
        
        .item > div{
            border: 1px solid white;
            border-radius: 0!important;
            box-shadow: none!important;
            background-color: #DCE4F7;
            font-family: 'Barlow';
            color: black!important;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        .buttonM {
            svg {
                margin-bottom: 2px;
            }
            .mglass{
                padding-bottom: 5px;
                margin-right: 3px;
            }
        }
        .refreshButton{
            border: solid white 1px!important;
            background-color: #254594!important;
            color: white!important;
            border-radius: 3px;
            transition:all 300ms;
            text-align: center;
            font-family: 'Barlow';
            padding-bottom: 6.5px!important;
            font-size: 1.7rem!important;
            line-height: 18px!important;
            height: 38px!important;
            transition: all 300ms;
            border-radius: 0!important;
            svg {
                // margin-bottom: 6.5px;
                margin-right: 10px;
                margin-left: 5px
            }
            &:hover{
                opacity: 0.8;
            }
        }
        .refreshButton:hover{
            opacity: 0.9;
            background-color: #9d9d9d;
        }
        .searchButton{
            border: solid white 1px!important;
            background-color: #254594!important;
            color: white!important;
            border-radius: 3px;
            transition:all 300ms;
            text-align: center;
            font-family: 'Barlow';
            padding-bottom: 6.5px!important;
            font-size: 1.7rem!important;
            line-height: 18px!important;
            height: 38px!important;
            transition: all 300ms;
            border-radius: 0!important;
            svg {
                // margin-bottom: 6.5px;
                margin-right: 10px;
                margin-left: 5px
            }
            &:hover{
                opacity: 0.8;
            }
        }
        .searchButton:hover{
            opacity: 0.9;
            background-color: #9d9d9d;
        }
    }

    .datePicker {
        display: block;
        width: 100%;

        .datePickerContainer {
            display: flex;
            margin-top: 50px;
            justify-content: center;
            &>div:first-child {
                display: none !important;
            }
        }

        .dates {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
            color: white;
            font-family: 'Barlow';
            font-size: 14px;
        }
    }
}