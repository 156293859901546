@import "../../_partials/fonts";
@import "../../_partials/form";

* {
    margin: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
html {
    font-size: 10px;
}
img {
    max-width: 100%;
    height: auto;
}
$font-size-base: 1.6rem;

$grid-gutter-width: 2.4rem;

$primary: #254594;
$secondary: #9d9d9d;
$info: #191c38;
$danger: #da251d;
$white: #ffffff;
$black: #000000;
$blue: #1c2e6d;
$light-blue: #4065b8;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5,
    6: $spacer * 3,
    7: $spacer * 3.5,
    8: $spacer * 4,
    9: $spacer * 4.5,
    10: $spacer * 5,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px,
);

$container-max-widths: (
    //sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1424px
);
body {
}
main {
}
