.wrapper {
    display: flex;
    position: fixed;
    width: 100%;
    height: 93vh;
    left: 0;
    background-color: white;
    @media (min-width: 992px) {
        position: sticky;
        flex: 0 0 55%;
        width: 55%;
        height: 73vh;
        top: 240px;
    }
    .mainWrapper {
        padding: 15px 15px 0px 15px;
        max-width: 98%;
        min-width: 98%;
        margin-inline: auto;
        position: relative;
        .close {
            position: absolute;
            top: 10px;
            right: 0px;
            cursor: pointer;
            svg {
                fill: #9d9d9d;
            }
        }
        .header {
            @media (min-width: 576px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            div {
                display: block;
                text-align: center;
                @media (min-width: 576px) {
                    display: flex;
                    align-items: center;
                }
                span {
                    display: block;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 17px;
                    color: #191c38;
                    margin-bottom: 20px;
                    @media (min-width: 576px) {
                        margin-bottom: 0;
                    }
                }
                a {
                    display: block;
                    text-align: center;
                    color: #ffffff;
                    background-color: #254594;
                    text-decoration: none;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 15px;
                    @media (min-width: 576px) {
                        margin-right: 15px;
                    }
                    svg {
                        fill: #ffffff;
                        margin-right: 11px;
                    }
                }
            }
        }
        .content {
            height: 77vh;
            overflow-y: scroll;
            margin-top: 5px;
            @media(min-width: 992px){
                height: 90%;
            }
            .contentTop > div {
                margin-inline: auto;
            }
            .contentTop {
                padding: 0px 7px 10px;
                h4 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.93px;
                    color: #9d9d9d;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    @media (min-width: 450px) {
                        white-space: nowrap;
                    }
                }
                h2 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    color: #000000;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media (min-width: 576px) {
                        font-size: 28px;
                        line-height: 34px;
                    }
                }
                figure {
                    &::after {
                        padding-top: 66%;
                    }
                }
                .prevLink {
                    display: flex;
                    position: absolute;
                    z-index: 999;
                    top: 180px;
                    padding: 5px;
                    left: 30px;
                    background-color: white;
                    border: solid gray 1px;
                    border-radius: 50%;
                }
                .nextLink {
                    display: flex;
                    position: absolute;
                    z-index: 999;
                    top: 180px;
                    padding: 5px;
                    left: 650px;
                    background-color: white;
                    border: solid gray 1px;
                    border-radius: 50%;
                }
                @media (max-width: 1500px) {
                    .prevLink {
                        top: 140px;
                        left: 20px;
                    }
                    .nextLink {
                        top: 140px;
                        left: 500px;
                    }
                }
                @media (max-width: 1200px) {
                    .prevLink {
                        top: 117px;
                        left: 10px;
                    }
                    .nextLink {
                        top: 117px;
                        left: 412px;
                    }
                }
                @media (max-width: 992px) {
                    .prevLink {
                        top: 45%;
                        left: 2%;
                    }
                    .nextLink {
                        top: 45%;
                        left: 93%;
                    }
                }
                @media (max-width: 576px) {
                    .prevLink {
                        top: 45%;
                        left: 2%;
                    }
                    .nextLink {
                        top: 45%;
                        left: 88%;
                    }
                }
            }
            .contentMiddle {
                color: black;
                padding: 38px 7px 10px;
                border-top: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                p {
                    font-family: 'Barlow';
                    font-weight: normal;
                    color: #254594;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
            .contentBottom {
                padding: 20px 25px 10px;
                div {
                    padding: 3px 0px;
                    border-bottom: #a8a8a8 1px dashed;
                    span {
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 26px;
                        letter-spacing: -0.22px;
                        color: #9d9d9d;
                    }
                }
            }
            .contentSlider {
                padding: 5px 7px 10px;
                position: relative;
                p {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.2px;
                    color: #4065b8;
                    margin-bottom: 20px;
                }
                .sliderWrapper {
                    display: flex;
                    overflow-x: auto;
                    .sliderItem {
                        flex: 0 0 23%;
                        width: 23%;
                        margin-right: 20px;
                        margin-bottom: 0;
                        @media (min-width: 576px) {
                            flex: 0 0 25%;
                            width: 25%;
                        }
                        @media (min-width: 1500px) {
                            flex: 0 0 26%;
                            width: 26%;
                        }
                        &:last-of-type {
                            margin-right: 0 !important;
                        }
                        div {
                            text-align: center;
                            figure {
                                margin-bottom: 9px;
                                img {
                                    object-fit: cover;
                                    max-height: 180px;
                                }
                            }
                            p {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 10px;
                                line-height: 13px;
                                color: #254594;
                                margin-bottom: 0;
                                @media (min-width: 576px) {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                }
                .sliderNavigation { 
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    a {
                        text-decoration: none;
                        &.prev {
                            position: absolute;
                            left: -4%;
                        }
                        &.next {
                            position: absolute;
                            right: -4%;
                        }
                    }
                }
            }
        }
    }
}

.scrollWrapper {
    display: flex;
    position: fixed;
    width: 100%;
    height: 93vh;
    left: 0%;
    background-color: white;
    @media (min-width: 992px) {
        position: sticky;
        flex: 0 0 55%;
        width: 55%;
        height: 88vh;
        top: 100px;
    }
    .mainWrapper {
        padding: 15px 15px 0px 15px;
        max-width: 98%;
        min-width: 98%;
        margin-inline: auto;
        position: relative;
        .close {
            position: absolute;
            top: 10px;
            right: 0px;
            cursor: pointer;
            svg {
                fill: #9d9d9d;
            }
        }
        .header {
            @media (min-width: 576px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            div {
                display: block;
                text-align: center;
                @media (min-width: 576px) {
                    display: flex;
                    align-items: center;
                }
                span {
                    display: block;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 17px;
                    color: #191c38;
                    margin-bottom: 20px;
                    @media (min-width: 576px) {
                        margin-bottom: 0;
                    }
                }
                a {
                    display: block;
                    text-align: center;
                    color: #ffffff;
                    background-color: #254594;
                    text-decoration: none;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 15px;
                    @media (min-width: 576px) {
                        margin-right: 15px;
                    }
                    svg {
                        fill: #ffffff;
                        margin-right: 11px;
                    }
                }
            }
        }
        .content {
            height: 77vh;
            overflow-y: scroll;
            margin-top: 5px;
            max-height: 92%;
            @media(min-width: 992px){
                max-height: 92%;
                height: 90%;
            }
            .contentTop > div {
                margin-inline: auto;
            }
            .contentTop {
                padding: 0px 7px 10px;
                h4 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.93px;
                    color: #9d9d9d;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    @media (min-width: 450px) {
                        white-space: nowrap;
                    }
                }
                h2 {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    color: #000000;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media (min-width: 576px) {
                        font-size: 28px;
                        line-height: 34px;
                    }
                }
                figure {
                    &::after {
                        padding-top: 66%;
                    }
                }
                .prevLink {
                    display: flex;
                    position: absolute;
                    z-index: 999;
                    top: 180px;
                    padding: 5px;
                    left: 30px;
                    background-color: white;
                    border: solid gray 1px;
                    border-radius: 50%;
                }
                .nextLink {
                    display: flex;
                    position: absolute;
                    z-index: 999;
                    top: 180px;
                    padding: 5px;
                    left: 650px;
                    background-color: white;
                    border: solid gray 1px;
                    border-radius: 50%;
                }
                @media (max-width: 1500px) {
                    .prevLink {
                        top: 140px;
                        left: 20px;
                    }
                    .nextLink {
                        top: 140px;
                        left: 500px;
                    }
                }
                @media (max-width: 1200px) {
                    .prevLink {
                        top: 117px;
                        left: 10px;
                    }
                    .nextLink {
                        top: 117px;
                        left: 412px;
                    }
                }
                @media (max-width: 992px) {
                    .prevLink {
                        top: 45%;
                        left: 2%;
                    }
                    .nextLink {
                        top: 45%;
                        left: 93%;
                    }
                }
                @media (max-width: 576px) {
                    .prevLink {
                        top: 45%;
                        left: 2%;
                    }
                    .nextLink {
                        top: 45%;
                        left: 88%;
                    }
                }
            }
            .contentMiddle {
                color: black;
                padding: 38px 7px 10px;
                border-top: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                p {
                    font-family: 'Barlow';
                    font-weight: normal;
                    color: #254594;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
            .contentBottom {
                padding: 20px 25px 10px;
                div {
                    padding: 3px 0px;
                    border-bottom: #a8a8a8 1px dashed;
                    span {
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 26px;
                        letter-spacing: -0.22px;
                        color: #9d9d9d;
                    }
                }
            }
            .contentSlider {
                padding: 5px 7px 10px;
                position: relative;
                p {
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.2px;
                    color: #4065b8;
                    margin-bottom: 20px;
                }
                .sliderWrapper {
                    display: flex;
                    overflow-x: auto;
                    .sliderItem {
                        flex: 0 0 23%;
                        width: 23%;
                        margin-right: 20px;
                        margin-bottom: 0;
                        @media (min-width: 576px) {
                            flex: 0 0 25%;
                            width: 25%;
                        }
                        @media (min-width: 1500px) {
                            flex: 0 0 26%;
                            width: 26%;
                        }
                        &:last-of-type {
                            margin-right: 0 !important;
                        }
                        div {
                            text-align: center;
                            figure {
                                margin-bottom: 9px;
                                img {
                                    object-fit: cover;
                                    max-height: 180px;
                                }
                            }
                            p {
                                font-family: 'Barlow';
                                font-weight: bold;
                                font-size: 10px;
                                line-height: 13px;
                                color: #254594;
                                margin-bottom: 0;
                                @media (min-width: 576px) {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
                .sliderNavigation { 
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    a {
                        text-decoration: none;
                        &.prev {
                            position: absolute;
                            left: -4%;
                        }
                        &.next {
                            position: absolute;
                            right: -4%;
                        }
                    }
                }
            }
        }
    }
}