.search-wrapper {
    padding: 22px 0 15px;
    background-color: $primary;
    position: sticky;
    width: 100%;
    top: 69px;
    z-index: 3;
    @media (min-width: 768px) {
        top: 87px;
    }
    .input-group {
        margin-bottom: 23px;
        height: 62px;
        .form-control {
            border-radius: 0;
            border: none !important;
            padding: 0.375rem 3.5rem;
            &:focus {
                box-shadow: none;
            }
        }
        #button-addon2 {
            border: none;
            background-color: $white;
            border-radius: 0;
            padding-right: 1.1rem;
            &:focus {
                box-shadow: none;
            }
            svg {
                fill: rgba(25, 28, 56, 0.6);
            }
        }
    }
    .search-action {
        span {
            color: $white;
            font-family: 'Barlow';
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
        }
        .download-all {
            color: $white;
            display: inline-block;
            text-decoration: none;
            padding: 5px 9px;
            background-color: rgba(25, 28, 56, 0.6);
            font-family: 'Barlow';
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;
            @media (min-width: 576px) {
                margin-left: auto;
                margin-bottom: 0;
            }
        }
        .select-wrapper {
            display: flex;
            align-items: center;
            @media (min-width: 576px) {
                padding-top: 0;
                margin-left: 20px;
            }
            .form-select {
                height: 32px;
                max-width: 80px;
                width: 100%;
                border: none;
                background-color: #dce4f7;
                font-family: 'Barlow';
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: $info;
                border-radius: 0;
                margin-right: 5px;
                -moz-appearance: none;
                &:last-of-type {
                    margin-right: 0;
                }
                @media (min-width: 576px) {
                    max-width: 96px;
                    margin-right: 20px;
                }
            }
        }
    }
}

.input-group {
    margin-bottom: 23px;
    height: 62px;
    .form-control {
        border-radius: 0;
        border: none !important;
        padding: 0.375rem 3.5rem;
        &:focus {
            box-shadow: none;
        }
    }
    #button-addon2 {
        border: none;
        background-color: $white;
        border-radius: 0;
        padding-right: 1.1rem;
        &:focus {
            box-shadow: none;
        }
        svg {
            fill: rgba(25, 28, 56, 0.6);
        }
    }
}   
