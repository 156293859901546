.grid-layout-photo {
    padding-bottom: 75px;
    .grid-layout-wrapper {
        @media (min-width: 992px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .grid-item-wrapper {
            @media (min-width: 992px) {
                flex: 0 0 calc(100%);
                width: calc(100%);
            }
            a {
                color: $white;
                text-align: center;
                display: block;
                margin: 0 auto;
                text-decoration: none;
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 20px;
                line-height: 25px;
                padding: 10px 0;
            }
            .layout-item {
                margin-bottom: 15px;
                position: relative;
                @media (min-width: 768px) {
                    display: flex;
                    width: 100%;
                }
                &.active {
                    border-right: 4px solid $danger;
                    &:after {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        background-color: $danger;
                        position: absolute;
                        right: -8px;
                        transform: translateY(-50%) rotate(45deg);
                        top: 50%;
                        border-top: 1px solid $danger;
                        border-left: 1px solid $danger;
                    }
                }
                .layout-item-img {
                    position: relative;
                    z-index: 2;
                    @media (min-width: 768px) {
                        flex: 0 0 213px;
                        width: 213px;
                        max-height: 158px;
                    }
                    @media (min-width: 992px) {
                        flex: 0 0 196px;
                        width: 196px;
                    }
                    @media (min-width: 1500px) {
                        flex: 0 0 213px;
                        width: 213px;
                    }
                    figure {
                        margin-bottom: 0;
                        height: 100%;
                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
                .layout-item-info {
                    background-color: $white;
                    padding: 22px 18px 16px 18px;
                    overflow: hidden;
                    position: relative;
                    z-index: 2;
                    @media (min-width: 576px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex: 1;
                    }
                    @media (min-width: 768px) {
                        padding: 15px 18px 15px 18px;
                    }
                    @media (min-width: 992px) {
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    @media (min-width: 1200px) {
                        padding: 17px 10px 18px 24px;
                    }
                    .layout-item-info-data {
                        margin-bottom: 20px;
                        @media (min-width: 576px) {
                            margin-bottom: 0;
                        }
                        @media (min-width: 768px) {
                            flex: 0 0 90%;
                            width: 90%;
                            margin-bottom: 0;
                        }
                        h4 {
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.93px;
                            color: $secondary;
                            font-family: 'Barlow';
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: 9px;
                            @media (min-width: 768px) {
                                font-size: 11px;
                                line-height: 13px;
                            }
                            @media (min-width: 992px) {
                                font-size: 10px;
                                line-height: 12px;
                            }
                            @media (min-width: 1200px) {
                                font-size: 13px;
                                line-height: 20px;
                            }
                        }
                        h2 {
                            font-size: 18px;
                            line-height: 20px;
                            color: $info;
                            font-family: 'Barlow';
                            font-weight: bold;

                            @media (min-width: 768px) {
                                font-size: 13px;
                                line-height: 17px;
                            }
                            @media (min-width: 992px) {
                                font-size: 13px;
                                line-height: 16px;
                            }
                            @media (min-width: 1200px) {
                                font-size: 18px;
                                line-height: 20px;
                            }
                        }
                    }
                    .layout-item-info-action {
                        display: flex;
                        align-items: center;
                        @media (min-width: 992px) {
                        }
                        .checkbox {
                            margin-bottom: 0;
                            .fake-checkbox {
                                flex: 0 0 16px !important;
                                width: 16px !important;
                                height: 16px !important;
                                border: 2px solid #9d9d9d !important;
                            }
                        }
                    }
                }
            }
        }
        .grid-item-wrapper.active{
            display: none;
            @media (min-width: 768px){
                display: block;
                flex: 0 0 calc(100% / 2 - 24px / 2);
                width: calc(100% / 2 - 24px / 2);
            }
            .layout-item {
                .layout-item-info {
                    h2 {
                        font-size: 18px;
                        line-height: 20px;
                        color: $info;
                        font-family: 'Barlow';
                        font-weight: bold;
                        @media (min-width: 992px) {
                            font-size: 15px;
                            line-height: 20px;
                            max-height: 70px;
                            max-width: 300px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        @media (min-width: 1200px) {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        .grid-sidebar-wrapper {
            background-color: $white;
            @media (min-width: 992px) {
                flex: 0 0 calc(100% / 2 - 24px / 2);
                width: calc(100% / 2 - 24px / 2);
                position: sticky;
                top: 235px;  
            }
            .grid-sidebar-main-wrapper {
                padding: 50px 18px 16px 18px;
                position: relative;
                @media (min-width: 1200px) {
                    padding: 79px 50px 31px 47px;
                }
                .close {
                    position: absolute;
                    top: 2%;
                    right: 2%;
                    cursor: pointer;
                    svg {
                        fill: #9d9d9d;
                    }
                }
                .grid-sidebar-header {
                    @media (min-width: 576px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    span {
                        display: block;
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 17px;
                        color: $info;
                        margin-bottom: 20px;
                        @media (min-width: 576px) {
                            margin-bottom: 0;
                        }
                        &:first-of-type {
                            margin-right: 15px;
                        }
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        &:first-of-type {
                            border: 1px solid rgba(25, 28, 56, 0.25);
                            display: flex;
                            align-items: center;
                            padding: 16px;
                            margin-right: 17px;
                        }
                        &:last-of-type {
                            background-color: $primary;
                            color: $white;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 28px;
                            padding: 12px 15px;
                            display: flex;
                            align-items: center;
                            svg {
                                fill: $white;
                                margin-right: 11px;
                            }
                        }
                    }
                }
                .grid-sidebar-content {
                    height: 500px;
                    padding-right: 15px;
                    overflow-y: scroll;
                    @media(max-width: 768px){
                        max-height: 450px;
                        overflow-y: scroll;
                    }
                    .grid-sidebar-content-top {
                        padding: 38px 0 10px;
                        h4 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.93px;
                            color: #9d9d9d;
                            margin-bottom: 6px;
                            text-transform: uppercase;
                            @media (min-width: 450px) {
                                white-space: nowrap;
                            }
                        }
                        h2 {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 26px;
                            color: $primary;
                            margin-bottom: 15px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @media (min-width: 576px) {
                                font-size: 28px;
                                line-height: 34px;
                            }
                        }
                        figure {
                            &::after {
                                padding-top: 66%;
                            }
                        }
                    }
                    .grid-sidebar-content-middle {
                        padding-bottom: 19px;
                        @media (min-width: 450px) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                        }
                        span {
                            display: block;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 26px;
                            letter-spacing: -0.22px;
                            color: #9d9d9d;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            color: $primary;
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 14px;
                            text-decoration: none;
                            padding-top: 20px;
                            @media (min-width: 450px) {
                                padding-top: 0;
                            }
                            svg {
                                fill: $primary;
                                margin-right: 5px;
                            }
                        }
                    }
                    .grid-sidebar-content-bottom {
                        padding: 18px 0;
                        border-top: 1px solid #eeeeee;
                        border-bottom: 1px solid #eeeeee;
                        p {
                            font-family: 'Barlow';
                            font-weight: normal;
                            color: $primary;
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                    .grid-sidebar-content-slider {
                        padding-top: 20px;
                        position: relative;
                        p {
                            font-family: 'Barlow';
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 19px;
                            letter-spacing: -0.2px;
                            color: #4065b8;
                            margin-bottom: 20px;
                        }
                        .slider-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .slider-item {
                                flex: 0 0 calc(100% / 3 - 40px / 3);
                                width: calc(100% / 3 - 40px / 3);
                                margin-right: 20px;
                                margin-bottom: 0;
                                &:last-of-type {
                                    margin-right: 0 !important;
                                }
                                figure {
                                    margin-bottom: 9px;
                                }
                                p {
                                    font-family: 'Barlow';
                                    font-weight: bold;
                                    font-size: 10px;
                                    line-height: 13px;
                                    color: $primary;
                                    margin-bottom: 0;
                                    @media (min-width: 576px) {
                                        font-size: 16px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        .slider-navigation { 
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            a {
                                text-decoration: none;
                                &.prev {
                                    position: absolute;
                                    left: -4%;
                                }
                                &.next {
                                    position: absolute;
                                    right: -4%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .loader{
        width: 100%;
        display: flex;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
    }
}
