.container {
    position: absolute;
    contain: content;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
}

.scrollLeft {
    position: absolute;
    background-color: white;
    border-radius: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    top: 30%;
    left: 10px;
    border: 1px solid;
    cursor: pointer;
    pointer-events: all;
}

.scrollRight {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
    border-radius: 12.5px;
    border: 1px solid;
    width: 25px;
    height: 25px;
    right: 20px;
    cursor: pointer;
    pointer-events: all;
}

.icon {
    padding: 5px;
}