.layout {
    padding-bottom: 75px;
    a {
        color: $white;
        text-align: center;
        display: block;
        margin: 0 auto;
        text-decoration: none;
        font-family: 'Barlow';
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        padding: 10px 0;
    }
    .layout-wrapper {
        .layout-item {
            margin-bottom: 15px;
            @media (min-width: 768px) {
                display: flex;
                width: 100%;
            }
            .layout-item-data {
                background-color: $blue;
                padding: 22px 18px 16px 18px;
                position: relative;
                @media (min-width: 768px) {
                    flex: 0 0 213px;
                    width: 213px; 
                }
                @media (min-width: 992px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    background-color: $danger;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 18px;
                }
                span {
                    color: $white;
                    display: block;
                    font-family: 'Barlow';
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: uppercase;
                }
                p {
                    color: $white;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    padding-top: 25px;
                }
            }
            .layout-item-info {
                background-color: $white;
                padding: 22px 18px 16px 18px; 
                @media (min-width: 768px) {
                    flex: 1;
                }
                @media (min-width: 992px) {
                    padding: 42px 40px 37px 24px;
                }
                @media (min-width: 1200px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 42px 89px 37px 24px;
                }
                h4 {
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.93px;
                    color: $secondary;
                    font-family: 'Barlow';
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom: 9px;
                }
                h2 {
                    font-size: 18px;
                    line-height: 20px;
                    color: $info;
                    font-family: 'Barlow';
                    font-weight: bold;
                }
                span {
                    color: $secondary;
                    font-family: 'Barlow';
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 17px;
                    text-transform: uppercase;
                }
            }
            .layout-item-action {
                background-color: $white;
                padding: 22px 0 16px 18px;
                @media (min-width: 768px) {
                    flex: 0 0 152px;
                    width: 152px;
                    display: flex;
                    align-items: center;
                    padding: 22px 25px 16px 18px;
                }
                @media (min-width: 1200px) {
                    padding: 22px 54px 16px 18px;
                }
                a {
                    text-decoration: none;
                    display: inline-block;
                    margin-right: 5px;
                    &:last-of-type {
                        margin-right: 0;
                        margin-left: 44px;
                        svg {
                            fill: $danger;
                        }
                    }
                }
            }
        }
    }
}
