@import "fonts";
@import "form";
html {
  font-size: 10px;
}

$font-size-base: 1.6rem;

$grid-gutter-width: 2.4rem;

$primary: #254594;
$secondary: #9d9d9d;
$info: #191c38;
$danger: #da251d;
$white: #ffffff;
$black: #000000;
$blue: #1c2e6d;
$light-blue: #4065b8;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.5,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  //sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1424px
);

body {
  color: $secondary !important;
  background-color: $primary !important;
  font-family: 'Barlow' !important;
  font-weight: normal !important;
 
}

h1 {
  font-size: 2.8rem;
  line-height: 3.4rem;
}
main {
  margin-top: 69px !important;
  min-height: calc(100vh - 211px);
  @media (min-width: 768px) {
    margin-top: 88px !important;
    min-height: calc(100vh - 146px);
  }

}
.img-placeholder {
  position: relative;
  display: block;

  &:after {
      content: "";
      display: block;
      padding-top: 100%;

      .blog-section & {
          padding-top: 67.9%;
      }
      .shops & {
          padding-top: 63.8%;
      }
  }
  img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
  }
}