.profile {
    background: linear-gradient(180deg, #2a50a6 0%, #1c2e6d 100%);

    .profile-wrapper {
        padding: 22px 0 26px;
        .profile-header {
            background-color: $white;
            padding: 10px;
            top: 70px;
            @media (min-width: 768px) {
                padding: 120px 51px 22px 45px;
            }

            h2 {
                font-family: 'Barlow';
                font-weight: bold;
                font-size: 30px;
                line-height: 35px;
                color: $primary;
                margin-bottom: 47px;
            }
            .header-wrapper {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                    font-family: 'Barlow';
                    font-weight: normal;
                    font-size: 9px;
                    line-height: 12px;
                    color: $primary;
                    text-transform: uppercase;
                    &:first-of-type {
                        font-weight: bold;
                    }
                    @media (min-width: 576px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    @media (min-width: 992px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
        .profile-content {
            background-color: $white;
            padding: 71px 10px 50px 10px;
            border-top: 2px solid $primary;
            @media (min-width: 768px) {
                padding: 67px 51px 118px 45px;
            }
            @media (max-width: 768px) {
                padding: 20px 10px 50px 10px;
            }
            .content-wrapper {
                display: flex;
                align-items: normal;
                justify-content: space-between;
                margin-bottom: 35px;
                @media (max-width: 768px) {
                    flex-direction: column;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                .profile-service {
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    .sub-row {
                        
                        position: relative;
                        margin-right: 20px;
                        font-family: 'BarlowSemi';
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        color: $primary;
                        margin-bottom: 0;
                        min-height: 50px; 
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-right: 1px solid #EEEEEE;
                        border-bottom: 1px solid #EEEEEE;
                        @media (min-width: 576px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                        @media(min-width: 768px){
                            min-height: 60px;
                        }
                        @media (min-width: 992px) {
                            font-size: 18px;
                            line-height: 22px;
                        }
                        @media (min-width: 1200px) {
                           min-height: 90px;
                        }
                        @media (max-width: 768px) {
                            border-right: 0;
                        }
                        span {
                            text-align: right;
                            width: 30%;
                            padding: 10px 0;
                        }
                        .title {
                            color:black;
                            margin-bottom: 0;
                            width: 60%;
                        }
                        &:first-of-type {
                            color: #2a50a6;
                            letter-spacing: -0.34px;
                            @media (min-width: 576px) {
                                font-size: 16px;
                                line-height: 18px;
                            }
                            @media (min-width: 992px) {
                                font-size: 22px;
                                line-height: 24px;
                            }
                        }
                        &.services {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            padding-right: 10px;

                            .title {
                                margin-top: 10px;
                                margin-bottom: 10px;
                                width: 100%;
                                text-align: center;
                                font-size: larger;
                                padding-bottom: 10px;
                                @media (max-width: 768px) {
                                    padding-bottom: 0;
                                }
                            }

                            .services-grid{
                                width: 100%;
                                display: grid;
                                grid-template-columns: 25% 25% 25% 25%;
                                @media (max-width: 768px) {
                                    grid-template-columns: 100%;
                                }
                                .grid-item {
                                    width: 100%;

                                    .service-title{
                                        border-right: 1px solid;
                                        
                                        border-bottom: 2px solid;
                                        @media (max-width: 768px) {
                                            border-right: 0px;
                                            margin-top: 10px;
                                        }
                                        svg{
                                            margin: 5px;
                                        }
                                    }

                                    .service-item{
                                        padding: 5px;
                                        border-bottom: 1px solid;
                                        border-right: 1px solid;
                                        
                                        @media (max-width: 768px) {
                                            border-right: 0px;
                                            font-size: 12px;
                                        }
                                        .service-item-description{
                                            font-size: small;
                                            color: grey;
                                            @media (max-width: 768px) {
                                                font-size: 10px
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.mobile {
                        @media (min-width: 768px) {
                            display: none;
                        }
                        padding-top: 20px;
                        padding-bottom: 20px;
                        border-bottom: 2.5px #1c2e6d solid;

                        p {
                            margin-bottom: 0;
                            font-family: 'Barlow';
                            font-weight: normal;
                            font-size: 9px;
                            line-height: 12px;
                            color: $primary;
                            text-transform: uppercase;
                            &:first-of-type {
                                font-weight: bold;
                            }
                            @media (min-width: 576px) {
                                font-size: 12px;
                                line-height: 15px;
                            }
                            @media (min-width: 992px) {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                    
                }
                .profile-download,
                .profile-call {
                    p {
                        font-family: 'Barlow';
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        color: #9d9d9d;
                        margin-bottom: 0;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #EEEEEE;
                        @media (min-width: 576px) {
                            font-size: 12px;
                            line-height: 17px;
                        }
                        @media(min-width: 768px){
                            height: 60px;
                        }
                        @media (min-width: 1200px) {
                           height: 90px;
                        }
                        &:first-of-type {
                            color: $white;
                            letter-spacing: -0.34px;
                            @media (min-width: 576px) {
                                font-size: 16px;
                                line-height: 18px;
                            }
                            @media (min-width: 992px) {
                                font-size: 22px;
                                line-height: 24px;
                            }
                        }
                    }
                }
                .profile-subscription {
                    p {
                        font-family: 'BarlowSemi';
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        color: $primary;
                        margin-bottom: 0;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #EEEEEE;
                        @media (min-width: 576px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                        @media(min-width: 768px){
                            height: 60px;
                        }
                        @media (min-width: 992px) {
                            font-size: 16px; 
                            line-height: 28px;
                        }
                        @media (min-width: 1200px) {
                            height: 90px;
                        }
                        &:first-of-type {
                            color: transparent;
                            letter-spacing: -0.34px;
                            @media (min-width: 576px) {
                                font-size: 16px;
                                line-height: 18px;
                            }
                            @media (min-width: 992px) {
                                font-size: 22px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
        .profile-service {
            flex: 0 0 calc(100% / 4 - 5px / 4);
            width: calc(100% / 4 - 5px / 4);
            &.details {
                @media (max-width: 768px) {
                    display: none;
                }
            }
            @media (min-width: 576px) {
                flex: 0 0 30%;
                width: 30%;
            }
            @media (min-width: 992px) {
                flex: 0 0 50%;
                width: 50%;
            }
        }
        .profile-download {
            flex: 0 0 calc(100% / 4 - 5px / 4);
            width: calc(100% / 4 - 5px / 4);
            @media (min-width: 576px) {
                flex: 0 0 20%;
                width: 20%;
            }
            @media (min-width: 992px) {
                flex: 0 0 25%;
                width: 25%;
            }
            @media (min-width: 1200px) {
                flex: 0 0 20%;
                width: 20%;
            }
        }
        .profile-call {
            flex: 0 0 calc(100% / 4 - 5px / 4);
            width: calc(100% / 4 - 5px / 4);
            @media (min-width: 576px) {
                flex: 0 0 20%;
                width: 20%;
            }
            @media (min-width: 992px) {
                flex: 0 0 15%;
                width: 15%;
            }
            @media (min-width: 1200px) {
                flex: 0 0 20%;
                width: 20%;
            }
        }
        .profile-subscription {
            flex: 0 0 calc(100% / 4 - 5px / 4);
            width: calc(100% / 4 - 5px / 4);
            @media (min-width: 576px) {
                flex: 0 0 15%;
                width: 15%;
            }
            @media (min-width: 992px) {
                flex: 0 0 8%;
                width: 8%;
            }
            @media (min-width: 1200px) {
                flex: 0 0 12%;
                width: 12%;
            }
        }
    }
}
